<template>
  <div class="flex flex-col min-h-screen overflow-hidden">

    <!-- Site header -->
    <Header />

    <!-- Page content -->
    <main class="flex-grow">

      <!-- Page sections -->
      <HeroHome />

      <Guard />
      
<!--       
      <Demo />
      <FeaturesHome />
      <ExploreCases />
      <FeaturesWorld />
      <TestimonialsCarousel />
      <UseCases />
      <PricingTables />
      <Credentials /> -->
      
      
      
      <!-- <Faqs />
      <Cta /> -->

    </main>

    <!-- Site footer -->
    <Footer />

  </div>
</template>

<script>

import Header from './../partials/safety/Header.vue'
import HeroHome from './../partials/safety/HeroHome.vue'
import Footer from './../partials/safety/Footer.vue'

export default {
  name: 'Home',
  components: {
    Header,
    HeroHome,
    Footer
  },
  created(){
      document.title = this.$i18n.t(`title`)
  },
}
</script>
